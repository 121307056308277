import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Header from '../components/header'
import Footer from '../components/footer'

import '../assets/scss/main.scss'

class Layout extends Component {

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll)
  }

  onScroll = (event) => {

    const parallax = () => {
      const $images = document.querySelectorAll('.image-1, .image-2, .image-3, .image-4, .image-5, .about__left, .about__right, .latest-listings__left, .latest-listings__right')
      const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight)
      const winBottom = viewHeight + window.scrollY
      const speed = 0.4
      const threshold = 0

      for (let i = 0, l = $images.length; i < l; i++) {

        let rect = $images[i].getBoundingClientRect()
        let above = rect.bottom - threshold
        let below = rect.top - viewHeight + threshold

        if (above >= 0 && below < 0) {
          let parentH = $images[i].closest('section').offsetHeight;
          // Number of pixels shown after block appear
          let imgBottom = ((winBottom - above) * speed);
          // Max number of pixels until block disappear
          let imgTop = winBottom + parentH;
          // Porcentage between start showing until disappearing
          let y = ((imgBottom / imgTop) * 100);
          // Porcentage between start showing until disappearing
          $images[i].style.transform = `translate(0px, -${y}%)`
        }
      }
    }
    requestAnimationFrame(parallax)
  }


  render() {
    const { children, location } = this.props

    return (
      <>
        <Header />
        <main>{ children }</main>
        <Footer />
      </>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
